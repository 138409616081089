<script setup></script>

<template>
  <div class="knoc-footer">
    <div class="j-c-a-i">
      By logging in, you agree to our Terms of Service
    </div>
    <div class="j-c-a-i">
      Powered by Knocnoc
    </div>
  </div>
</template>

<style scoped>
  .knoc-footer {
    position: absolute;
    bottom: 15px;
    left: 16px;
    right: 16px;
    font-size: 10px;
    color: var(--text-t-3);
    line-height: 1.5;
    border-top: 1px solid rgb(64, 64, 64);
    padding-top: 5px
  }
</style>
